import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/common/Layout';
import RightToBeForgottenSuccess from '../../components/from/RightToBeForgotten/RightToBeForgottenSuccess';

const RightToBeForgottenSuccessPage = () => (
  <Layout lang="en">
    <SEO
      title="Right to be forgotten successfully - Nextcloud Hosting"
      description="Successfully, your data will be deleted."
    />
    <RightToBeForgottenSuccess lang="en" />
  </Layout>
);

export default RightToBeForgottenSuccessPage;
